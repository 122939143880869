import AuthGuard from 'components/auth/auth-guard';
import GuestGuard from 'components/auth/guest-guard';
import { RepaymentPlanFeatureFlag, ShopFeatureFlag } from 'components/feature-flag-user-group';
import BlankLayout from 'components/layouts/blank-layout';
import MainLayout from 'components/layouts/main-layout';
import { PageLoader } from 'components/loader';
import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import NotFound from 'routes/not-found';
import DebitCardRepaymentSuccess from 'routes/repayment/debit-card-repayment-success';
import { buyCardAction } from 'routes/shop/card-product';
import EmploymentDetails from './routes/employment-details';

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => (
  <Suspense fallback={<PageLoader />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import('routes/auth/login')));
const EmailRecovery = Loadable(lazy(() => import('routes/auth/email-recovery')));
const PasswordChange = Loadable(lazy(() => import('routes/auth/password-change')));
const PasswordRecovery = Loadable(lazy(() => import('routes/auth/password-recovery')));
const PasswordReset = Loadable(lazy(() => import('routes/auth/password-reset')));
// const VerifyCode = Loadable(lazy(() => import('routes/VerifyCode')));

const Dashboard = Loadable(lazy(() => import('routes/dashboard')));
const Profile = Loadable(lazy(() => import('routes/profile')));
const Repayment = Loadable(lazy(() => import('routes/repayment')));
const RepaymentDebitCard = Loadable(lazy(() => import('routes/repayment/debit-card')));
const RepaymentPlanOptions = Loadable(lazy(() => import('routes/repayment/plan-options')));
const Withdraw = Loadable(lazy(() => import('routes/withdraw')));

const CardProductBrowse = Loadable(lazy(() => import('routes/shop/card-product-browse')));
const CardProduct = Loadable(lazy(() => import('routes/shop/card-product')));
const CardOrderCreate = Loadable(lazy(() => import('routes/shop/card-order-create')));
const CardOrderProcess = Loadable(lazy(() => import('routes/shop/card-order-process')));
const CardOrderReceipt = Loadable(lazy(() => import('routes/shop/card-order-receipt')));

const BankData = Loadable(lazy(() => import('routes/bank-data')));

export const routes: RouteObject[] = [
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'email-recovery',
        element: <EmailRecovery />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'password-change',
        element: <PasswordChange />,
      },
      // {
      //   path: 'verify-code',
      //   element: <VerifyCode />,
      // },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'profile', element: <Profile /> },
      { path: 'withdraw', element: <Withdraw /> },
      {
        path: 'shop/browse',
        element: (
          <ShopFeatureFlag redirectOnUnauthorized>
            <CardProductBrowse />
          </ShopFeatureFlag>
        ),
      },
      {
        path: 'shop/product/:cardProductId',
        element: (
          <ShopFeatureFlag redirectOnUnauthorized>
            <CardProduct />
          </ShopFeatureFlag>
        ),
        action: buyCardAction,
      },
      {
        path: 'shop/order/create',
        element: (
          <ShopFeatureFlag redirectOnUnauthorized>
            <CardOrderCreate />
          </ShopFeatureFlag>
        ),
      },
      {
        path: 'shop/order/process',
        element: (
          <ShopFeatureFlag redirectOnUnauthorized>
            <CardOrderProcess />
          </ShopFeatureFlag>
        ),
      },
      {
        path: 'shop/order/:cardOrderId/receipt',
        element: (
          <ShopFeatureFlag redirectOnUnauthorized>
            <CardOrderReceipt />
          </ShopFeatureFlag>
        ),
      },
      {
        path: 'repayment',
        element: <Repayment />,
      },
      {
        path: 'repayment/plan/options',
        element: (
          <RepaymentPlanFeatureFlag redirectOnUnauthorized>
            <RepaymentPlanOptions />
          </RepaymentPlanFeatureFlag>
        ),
      },
      {
        path: 'repayment/debit-card',
        element: <RepaymentDebitCard />,
      },
      {
        path: 'repayment/debit-card/success',
        element: <DebitCardRepaymentSuccess />,
      },
      {
        path: 'bank-data',
        element: <BankData />,
      },
      {
        path: 'employment-details',
        element: <EmploymentDetails />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <BlankLayout>
        <NotFound />
      </BlankLayout>
    ),
  },
];
